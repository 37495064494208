import {useRouteError, isRouteErrorResponse} from '@remix-run/react';
import type {PropsWithChildren} from 'react';

import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import ShopifyLogo from '@/components/brochureV2/section/Header/svg/ShopifyLogoClient';
import SideBySide from '@/components/base/modules/SideBySide/SideBySide';
import env from '@/utils/env';

import Wrapper from './Wrapper';
import Provider from './Provider';

interface ErrorWrapperProps {
  title?: string;
}

function ErrorWrapper({title, children}: PropsWithChildren<ErrorWrapperProps>) {
  return (
    <Provider data={{}}>
      <Wrapper title={title}>
        <div className="relative">
          <header
            className="z-50 w-full bg-black text-white"
            data-component-name="global-nav"
            data-has-secondary-navigation="false"
          >
            <div className="h-global-header container flex items-center">
              <div className="nav:w-[125px] mr-12 w-[98px] shrink-0">
                <ShopifyLogo darkBackground={true} />
              </div>
            </div>
          </header>
          <main role="main" id="main" tabIndex={-1}>
            {children}
          </main>
        </div>
      </Wrapper>
    </Provider>
  );
}

export function ErrorBoundary() {
  const error: any = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <ErrorWrapper title={`${error.status} ${error.statusText}`}>
        <Section className="bg-shade-20">
          <SideBySide
            image={{
              src: 'https://cdn.shopify.com/b/shopify-brochure2-assets/3c2b4c9a5e7ce65e65171ae42701cc50.svg',
            }}
            verticalAlign="top"
            visualPosition="right"
          >
            {error.status === 404 ? (
              <HeadingGroup
                headingHtml="404: Page not found"
                subheadHtml='Check the web address and try again or return to the Shopify <a href="/" className="underline">home page</a>.'
              />
            ) : (
              <HeadingGroup
                headingHtml={`${error.status}: ${error.statusText}`}
                subheadHtml='Something has gone wrong and the page could not be displayed. Try the <a href="/" className="underline">home page</a>.'
              />
            )}
          </SideBySide>
        </Section>
      </ErrorWrapper>
    );
  }

  console.error(error.stack || error.message);

  return (
    <ErrorWrapper title="Application Error!">
      {env('SHOPIFY_APP_ENV') === 'production' ? (
        <Section className="bg-shade-20">
          <SideBySide
            image={{
              src: 'https://cdn.shopify.com/b/shopify-brochure2-assets/3c2b4c9a5e7ce65e65171ae42701cc50.svg',
            }}
            verticalAlign="top"
            visualPosition="right"
          >
            <HeadingGroup
              headingHtml="Application Error"
              subheadHtml="Something has gone wrong and the page could not be displayed. Try the home page."
            />
          </SideBySide>
        </Section>
      ) : (
        <Section topSpacing="2xl">
          <SectionHeader
            headingHtml="Application Error"
            subheadHtml="Something has gone wrong and the page could not be displayed."
          />
          <Grid>
            <Col start={1} span={{xs: 4, sm: 8, md: 12, lg: 12, xl: 12}}>
              <pre className="p-4 overflow-auto bg-banana-10">
                {error instanceof Error
                  ? error.stack || error.message
                  : 'Unknown Error.'}
              </pre>
            </Col>
          </Grid>
        </Section>
      )}
    </ErrorWrapper>
  );
}
